
<template>
  <div class="app">
    <h1>版权声明</h1>
    <h2>用户在接受源调试服务之前，请务必仔细阅读本条款并同意本声明。</h2>
    <h2>用户直接或通过各类方式间接使用源调试服务和数据的行为，均将被视作已接受本声明所涉全部内容；若用户对以下声明的任一条款持有异议，请终止使用源调试所提供的全部服务。</h2>
    <ul>
      <li>1、用户发表在源调试的原创图片等内容的版权均归用户本人所有。源调试仅提供平台和空间供注册用户上传、浏览和下载内容，内容系用户自行上传。</li>
      <li>2、用户从源调试或其他网站通过转载、复制、截图等方式获取他人内容，并发布在源调试的，相关内容的版权属于原作者。用户不得侵犯他人包括版权在内的知识产权及其他权利，因用户发布侵权作品引发知识产权或其他法律纠纷的，用户须自行承担一切后果，与源调试无关。</li>
      <li>3、源调试有权对用户发布的内容进行审核，并有权根据相关证据结合《中华人民共和国民法典》《信息网络传播权保护条例》等法律法规对侵权信息进行处理。</li>
      <li>4、源调试尊重权利人的知识产权和合法权益。若权利人认为源调试用户上传的内容侵犯自身版权或其他合法权益，可依法向源调试投诉邮箱【2257376760@qq.com】发出书面申请，申请应当包括构成侵权的初步证据及权利人的真实身份信息。源调试在审核相关材料后，有权自行决定对相关内容采取删除、屏蔽或断开链接等必要措施，并依法保留相关数据。在源调试发布内容，即视为用户同意源调试就前述情况所采取的相应措施，源调试不因此而承担任何违约、侵权或其他法律责任。</li>
      <li>5、源调试根据上述第4条原因删除相关内容的，相应发布用户如认为投诉不属实，可依法向源调试投诉邮箱【2257376760@qq.com】发出关于被采取必要措施内容不侵权的书面声明，声明应当包括不存在侵权行为的初步证据及网络用户的真实身份信息。声明发出后，源调试在书面审核相关材料后，根据实际情况依法进行处理。</li>
    </ul>
  </div>
</template>


<script>
export default {
  name: "bqsm", //用户协议
};
</script>



<style lang="scss" scoped>
body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 20px;
  font-size: 16px;
}
h2,
h3,
p {
  color: #333;
  font-size: 16px;
  margin-left: 80px;
}
h1 {
  text-align: center;
  font-size: 32px;
}
ul {
  list-style-type: none;
  padding-left: 20px;
  font-size: 16px;
  margin-left: 80px;
}

ul ul {
  padding-left: 40px;
  font-size: 16px;
}
li,
p {
  margin-bottom: 10px;
  font-size: 16px;
  text-indent: 2ch;
}
.app {
  padding-bottom: 30px;
}
</style>