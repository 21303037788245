<template>
  <div class="app">
    <h1>源调试账号注销协议</h1>
    <p>
      【特别说明】尊敬的用户，在您正式开始下一步源调试账号注销流程前，我们先为您做出如下特别说明：
      注销“源调试”账号后，您将无法再以此账号登录和使用“源调试”软件及相关服务，“源调试”也将同时终止为该账号提供我们的高品质、多元化的产品与服务，这同时也不可避免地会给您的售后维权带来不便。且“源调试”账号一旦注销完成，将无法恢复。请您在注销前慎重考虑。
    </p>
    <p>
      若您经过慎重考虑后仍执意决定注销“源调试”账号的，请您务必先行仔细阅读和充分理解本《账号注销协议》，在同意全部内容后，按照我们的注销操作指引点击进行下一步操作，您勾选本注销协议并点击下一步注销操作的行为即视为您已经同意签署和遵守本协议全部内容。如您在注销后需要再次使用我们的服务的，欢迎您使用手机号码重新注册。同时，我们也非常希望能够了解您使用我们的产品/服务体验，您可以通过客服反馈给我们，以便我们进一步改进和完善我们的产品和服务。
    </p>
    <h2>
      一、您知悉并同意：在您的账号注销后，将（可能）产生包括但不限于如下结果，并需要您自行承担：
    </h2>
    <p>
      1、您将无法登录、使用该账号，也就是说您将无法再以此账号登录/使用/继续使用“源调试”软件与相关服务，例如：下载素材、配置、音效软件、上传素材、免广告服务等；
    </p>
    <p>
      2、您的“源调试”PC版账户也将被注销，您曾通过该账号登录、使用的所有内容、信息、数据、记录将会被删除或匿名化处理，您也无法再检索、访问、获取、继续使用和找回，也无权要求我们找回（但法律法规另有规定或监管部门另有要求的除外），包括：
    </p>
    <p>
      (1) 该账号下的个人资料（例如：头像、昵称等）及绑定信息（例如：绑定手机号）
    </p>
    <p>(2) 该账号曾发表的所有内容（例如：素材、配置、评论、互动、点赞等）；</p>
    <p>(3) 其他所有内容、信息、数据、记录。</p>
    <h2>
      3、您申请账号注销的行为即表明您同意放弃该账号在使用期间已产生的但未消耗完毕的权益及未来的预期利益。账号一旦注销，我们将会对该账号下的全部权益做清除处理，包括：
    </h2>
    <p>(1) 您尚未使用的源调试金币与会员时间；</p>
    <p>(2) 您在“源调试”的产品与/或服务中购买的素材与配置；</p>
    <p>(3) 您在“源调试”的产品与/或服务中发布的内容未来的收益权益；</p>
    <p>(4) 您在“源调试”的产品与/或服务中的各类身份权益；</p>
    <p>(5) 其他已产生但未消耗完毕的权益或未来预期的收益。</p>
    <h2>
      4、“源调试”账号一旦注销完成，将无法恢复（即使您在注销后以同一手机号码再次向我们申请注册账号，此时该账号为新的用户账号。因此，我们善意地提醒您在申请注销前对您认为需要备份的内容提前自行做好备份）；
    </h2>
    <h2>
      5、“源调试”账号一旦注销，您与我们曾签署过的相关用户协议、其他权利义务性文件等相应终止（但我们与您之间已约定继续生效的或法律法规另有规定的除外）；
    </h2>
    <h2>6、其他因“源调试”账号注销产生的结果。</h2>
    <h2>
      二、在您向我们申请注销“源调试”账号之前或正在申请注销账号的过程中，为保障您的账号安全和财产权益，请您先行检查与确保您申请注销的“源调试”账号已经同时满足以下几点，包括：
    </h2>
    <p>1、该账号处于安全状态，无未处理完成的被投诉、举报，以及其他异常状态。</p>
    <p>
      2、该账号内虚拟财产将被永久收回，包括但不限于消除广告计划、源调试金币、购买的素材、配置等虚拟财产。
    </p>
    <p>3、其他为了保障您的账号安全和财产权益需要满足的内容。</p>
    <h2>三、如何注销源调试账号？</h2>
    <p>
      1、pc端的注销功能路径为：我的-账号安全-账号注销。您可以按照页面操作指引进行在线注销。当然，您也可以直接与我们的客服联系进行账号注销；
    </p>
    <p>
      2、本《账号注销协议》中包含了账号注销对您权益产生的影响等多方面的内容阐述，请您仔细阅读与充分理解，在同意本协议的全部内容后再进行下一步注销操作；
    </p>
    <p>
      3、账号注销的处理期限为7日（从成功提交申请之时起算），7日后账号将自动永久注销（本协议另有约定的除外）。在前述7日内，您可以正常使用该账号，且无法使用该账号绑定的手机号码注册新账号；但是，在此期间内，您可以撤回注销；
    </p>
    <p>
      本协议未尽事宜，请参考注销过程中的平台相关规则、说明等。同时，您知悉并同意：即使您的“源调试”账号被注销了，也并不代表减轻或免除您应根据相关法律法规、相关协议、规则等（可能）需要承担的相关责任。
    </p>
    <p>
      如在“源调试”账号注销过程中有任何问题您无法处理的或在处理过程中有任何疑惑的，可联系“源调试”客服协助处理。
      本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律。因本协议产生之争议，双方尽最大诚意进行友好协商，如果双方不能协商一致，均应依照中华人民共和国法律予以处理，并由武汉市人民法院管辖。
    </p>
    
  </div>
</template>

<script>
export default {
  name: "zxxy",
};
</script>
<style lang="scss" scoped>
body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 20px;
  font-size: 16px;
}
h2,
h3,
p {
  color: #333;
  font-size: 16px;
  margin-left: 80px;
}
h1 {
  text-align: center;
  font-size: 32px;
}
ul {
  list-style-type: none;
  padding-left: 20px;
  font-size: 16px;
  margin-left: 80px;
}

ul ul {
  padding-left: 40px;
  font-size: 16px;
}
li,
p {
  margin-bottom: 10px;
  font-size: 16px;
  text-indent: 2ch;
}
.app {
  padding-bottom: 30px;
}
</style>