<template>
  <div class="app">
    <h1>平台规范</h1>
    <h2>一、价值观</h2>
    <ul>
      <li>平台倡导用户发布符合当代社会主流价值观的内容。</li>
      <li>1.1 遵守宪法和法律法规</li>
      <li>1.2 践行社会主义核心价值观</li>
      <li>1.3 弘扬爱国主义、集体主义和社会主义</li>
      <li>1.4 传播正确的历史观、民族观、国家观和文化观</li>
      <li>1.5 弘扬中华民族优秀传统文化</li>
      <li>1.6 弘扬社会公德、职业道德、家庭和个人美德，尊重公序良俗</li>
      <li>1.7 弘扬科学精神、普及科学知识</li>
      <li>1.8 提倡积极健康向上的时代风尚和生活方式</li>
    </ul>

    <h2>二、法律法规</h2>
    <ul>
      <li>
        平台对于社区内违反法律法规、危害国家及社会安全的行为，将采用最严格的管理办法，予以杜绝。
      </li>
    </ul>

    <h3>2.1 遵守基本准则</h3>
    <ul>
      <li>平台禁止发布和传播包含下列信息的内容：</li>
      <li>2.1.1 违反宪法所确定的基本原则的</li>
      <li>2.1.2 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的</li>
      <li>2.1.3 损害国家荣誉和利益的，如损害国旗国徽形象的</li>
      <li>2.1.4 煽动民族仇恨、民族歧视，破坏民族团结的</li>
      <li>2.1.5 破坏国家宗教政策，宣扬邪教和封建迷信的</li>
      <li>2.1.6 散布谣言，扰乱社会秩序，破坏社会稳定的</li>
      <li>2.1.7 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的</li>
      <li>2.1.8 侮辱或者诽谤他人，侵害他人合法权益的</li>
      <li>2.1.9 宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的</li>
      <li>
        2.1.10
        侮辱滥用英烈形象，否定歪曲英烈事迹，美化或粉饰侵略者和侵略战争行为的
      </li>
      <li>
        2.1.11 攻击诋毁党和国家领导人，捏造领导人负面信息，滥用领导人形象的
      </li>
      <li>2.1.12 违背公序良俗的内容，如低俗婚闹等</li>
      <li>2.1.13 含有法律、行政法规禁止的其他内容的</li>
    </ul>
    <h3>2.2 保护未成年人合法权益</h3>
    <ul>
      <li>为更好地承担保护未成年人合法权益的社会责任，平台拒绝未成年使用。</li>
    </ul>
    <h3>2.3 抵制一切违法信息</h3>
    <ul>
      <li>
        2.3.1
        禁止传播违法内容涉及违禁物品，包括但不限于：毒品、违禁药物、违法工具等，如大麻、鸦片、处方药物、投资理财产品、假币、枪支、烟草、爆炸物、翻墙软件、外挂程序等其他内容；涉及违禁活动，包括但不限于：有关传销、胎儿性别鉴定、售卖假货等其他内容
      </li>
      <li>
        2.3.2
        禁止发布欺诈或赌博信息发布欺诈信息，如假冒官方客服、正规网站，以短信、站内私信或链接等形式提示虚假中奖信息，设计“杀猪盘”类欺诈行为等；提供赌博交易平台信息或赌博性质类服务，如赌博平台链接、币商、中介、游戏币回收等；涉及赌博技巧、赌博器具等赌博内容，如老虎机、透视扑克等
      </li>
    </ul>
    <h3>2.4 抵制一切色情低俗内容</h3>
    <ul>
      <li>禁止发布、传播的信息包括但不限于：</li>
      <li>2.4.1 直接暴露和描写人体性部位的内容</li>
      <li>2.4.2 表现或隐晦表现性行为、具有挑逗性或者侮辱性的内容</li>
      <li>2.4.3 以带有性暗示、性挑逗的语言描述性行为、性过程、性方式的内容</li>
      <li>2.4.4 全身或者隐私部位未着衣物，仅用肢体掩盖隐私部位的内容</li>
      <li>2.4.5 带有侵犯个人隐私性质的走光、偷拍、漏点等内容</li>
      <li>2.4.6 以庸俗和挑逗性标题吸引点击的内容</li>
      <li>
        2.4.7
        相关部门禁止传播的色情和有伤社会风化的文字、音视频内容，包括一些电影的删节片段
      </li>
      <li>2.4.8 传播一夜情、换妻、性虐待等的有害信息</li>
      <li>2.4.9 情色动漫、小说</li>
      <li>2.4.10 宣扬暴力、恶意谩骂、侮辱他人等的内容</li>
      <li>2.4.11 非法的性药品广告和性病治疗广告等相关内容</li>
      <li>2.4.12 恶意传播侵害他人隐私的内容</li>
      <li>2.4.13 推介淫秽色情网站和网上低俗信息的链接、图片、文字等内容</li>
    </ul>

    <h2>三、不当行为</h2>
    <ul>
      <li>
        平台不鼓励发布不友好、欺骗、不当或危险行为的内容，禁止任何作弊信息及行为，包括但不限于以下内容：
      </li>
      <li>
        3.1 发布含人身攻击、骚扰他人的内容，如辱骂、侮辱、恶意引战、故意骚扰等
      </li>
      <li>3.2 发布含不文明的行为，如禁烟场所吸烟、妨碍导盲犬、随地吐痰等</li>
      <li>
        3.3 发布严重影响用户体验的内容，如首图血腥恐怖、标题党、图文不符等
      </li>
      <li>
        3.4
        分享的产品和内容无相关性、无真实体验经历、未还原真实感受、过分参照商家指引或使用过多官方宣传语句
      </li>
      <li>3.5 发布其他不符合大众审美的内容</li>
      <li>3.6 编造公众人物的社会谣言，如绝症、去世或其他有违事实的信息</li>
      <li>
        3.7
        发布违背科学常识的内容，如在食品安全、健康养生等领域发布已被官方或权威机构辟谣的内容
      </li>
      <li>
        3.8 易引人模仿的危险行为，如危险驾驶车辆，不当使用明火、干扰电梯等
      </li>
      <li>
        3.9
        无安全提示、无防护措施的危险行为，如无提示的极限运动、无防护措施的高空行为等
      </li>
      <li>3.10 其他破坏平台秩序不利于的情形</li>
    </ul>

    <h2>四、侵权行为</h2>
    <ul>
      <li>
        平台禁止一切侵犯他人合法权益的行为，如模仿冒充、抄袭搬运，包括但不限于以下内容：
      </li>
      <li>4.1 未经授权使用信息，冒充他人注册账号或使用他人名称、头像等</li>
      <li>
        4.2
        通过发布不实言论，散播虚假信息，损害他人名誉、商誉的内容，如他人的感情生活、商业负面等
      </li>
      <li>
        4.3
        未经授权发布他人身份证号码、家庭住址、病史、财产信用状况等个人隐私资料
      </li>
      <li>
        4.4 未经他人授权，发布内容侵犯他人著作权权益，如视频，照片，图文等
      </li>
      <li>4.5 侵犯他人已经登记注册的企业名称、商标、商号、商品装潢等</li>
      <li>4.6未经他人授权，发布内容侵犯他人专利权权益，如许诺销售等</li>
      <li>4.7 未经授权披露商业机密或其他保密信息的内容</li>
      <li>4.8 发布内容用以商品信息</li>
    </ul>

    <h2>五、违规处理</h2>
    <h3>5.1 认定标准</h3>
    <ul>
      <li>符合下述任一的，可认定用户违规：</li>
      <li>5.1.1 人工排查发现违反本规范情形的</li>
      <li>5.1.2 系统对一定周期内的数据进行排查后，抓取到异常数据的</li>
    </ul>
    <h3>5.2 违规处理措施</h3>
    <ul>
      <li>
        平台会基于其独立认定的事实（违规性质、严重程度等，下同），独立确定采取如下一项或几项处理措施的组合：
        限制展示范围、禁止展示、依法追究法律责任并对账号处理限制账号功能，如：限制发布、账号禁言、账号封禁或永久封禁、依法追究法律责任。
      </li>
      <li>
        平台将根据法律法规和调整和修改规范。为维护社区良好氛围，任何有违社区规范或扰乱社区生态秩序的行为，平台有权依据规范进行处理
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ptgf", //平台规范
};
</script>

<style lang="scss" scoped>
body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 20px;
  font-size: 16px;
}
h2,
h3,
p {
  color: #333;
  font-size: 16px;
  margin-left: 80px;
}
h1 {
  text-align: center;
  font-size: 32px;
}
ul {
  list-style-type: none;
  padding-left: 20px;
  font-size: 16px;
  margin-left: 80px;
}

ul ul {
  padding-left: 40px;
  font-size: 16px;
}
li,
p {
  margin-bottom: 10px;
  font-size: 16px;
}
.app{
  padding-bottom: 30px;
}
</style>