
<template>
  <div class="app">
    <h1>用户服务协议</h1>
    <h2>
      欢迎使用“源调试”平台（下称“平台”，由武汉市源来信息技术有限公司提供）。请您务必审慎阅读、充分理解以下内容，特别是免除或者限制平台责任的条款、对用户权利进行限制的条款的条款等。您阅读、完全理解并接受本协议所有条款，否则您无权使用平台。如您不同意本协议或其中任何条款，您应立即停止使用平台。您点击“同意”，或者您使用平台，或者以其他任何明示或者默示方式表示接受本协议的，均视为您已阅读并同意本协议。本协议即在您与武汉市源来信息技术有限公司之间产生法律效力，成为对双方均具有约束力的法律文件。本协议是您与公司之间关于您下载、安装、使用平台所订立的协议，包括本协议正文、《隐私政策》、《平台规范》及已经发布的或将来可能发布/更新的并采取合理途径通知的各类规则、规范、通知等。所有规则均为本协议不可分割的组成部分，且具有同等法律效力。
    </h2>
    <h2>第一条 定义</h2>
    <ul>
      <li>
        1.1平台：指域名为https://www.yuantiaoshi.com/的网站、“源调试”客户端软件ＡＰＰ及基于前述网站或软件的关联网站或软件小程序等。
      </li>
      <li>
        1.2平台服务：指公司通过平台为您提供内容浏览、内容发布、商品与服务交易、直播调试以及包括但不限于收藏、下载、评论、分享、转发、搜索查询、平台活动等功能或服务，具体以平台实际提供的为准。同时，平台有权对提供的功能或服务进行增加、关停或其他调整。
      </li>
      <li>
        1.3账号：指用户在使用平台服务时可能需要注册的账号。用户可在平台注册或通过微信号、手机号注册并获得的账号，作为登录并使用平台的凭证。
      </li>
      <li>1.4用户：指平台的使用人，或称为“您”。</li>
      <li>
        1.5金币：平台开发的对接微信或支付宝钱包，仅仅用于平台间内部结算、消费、支付的虚拟货币，不可以提现。充值前请仔细核对充值或消费内容及价格，理性消费。
      </li>
    </ul>
    <h2>第二条 账号</h2>
    <ul>
      <li>
        2.1平台为您提供注册及登录通道，也可以通过您授权的第三方如微信、支付宝、手机号码登录。您可按照页面提示填写信息、阅读并同意本协议，在完成全部注册登录程序后，成为平台用户。您还需要根据法律法规的规定（如有）填写您真实的身份信息，否则您可能无法使用平台服务或在使用受限。您了解并同意，您有义务保持您提供信息的真实性、有效性及完整性。
      </li>
      <li>
        2.2您的账号及密码是您使用平台的身份凭证，你应当谨慎使用、妥善保管。若因您保管不善导致的盗号、密码丢失、账号被非法使用等责任由您自行承担。特别是您通过微信、支付宝、手机号码等第三方登录注册和登录。
      </li>
      <li>
        2.3您在注册或使用平台时设置的名称、头像和简介等账号信息资料应遵守法律法规、社会道德风尚、政治攻讦或和信息真实性等原则，不得出现不良信息，不得冒用他人姓名、商标、名称、字号、头像等或采取其他足以引起混淆的方式设置账号，不得侵害第三方的合法权益。
      </li>
      <li>
        2.4
        由于您的账号关联您的个人信息及平台商业信息，您的账号仅限您本人使用。未经同意，您直接或间接授权第三方使用您账号或获取您账号项下信息的行为无效。如平台判断您账号的使用可能违反法律法规、危及您的账号安全及/或平台信息安全的，平台可拒绝提供相应服务或终止本协议。平台有权视情况封禁或注销、回收该账号，由此带来的包括但不限于内容、虚拟财产或金币清空等损失由您本人及该账号使用者自行承担。
      </li>
      <li>
        2.5您可以依照平台的流程申请注销您的账号，但您仍应对您在注销账号前或使用平台服务期间的行为承担相应责任。注销成功后，除非法律法规另有规定，平台不会为您提供账号记录、内容、虚拟财产等恢复或提供服务，请您谨慎操作。
      </li>
      <li>
        2.6如您在注册后未及时进行初次登录使用或连续超过12个月未登录账号并使用，平台有权收回您的账号。如您的账号被收回，您无法通过您此前持有的账号登录并使用平台，您账号下保存的个性化设置和使用记录也将无法恢复。
      </li>
      <li>
        2.7若您在注册或使用账号时，存在任何违反法律法规或不符合本协议约定的行为，平台有权不予注册；已经注册的，平台有权视情况要求用户限期改正、或单方采取短期封禁、永久封禁、注销账号等措施。
      </li>
      <li>
        2.8平台与您确认：您的账号仅为使用平台的凭证，代表您有使用平台的权限。平台才是账号的最终所有权人。如遇平台软件下架，平台会通过系统提前30天通知您，其中VIP会员客户可继续使用软件至VIP会员期满由平台无偿收回账号，普通会员通知到期后由由平台无偿收回账号。
      </li>
    </ul>
    <h2>第三条 平台服务</h2>
    <ul>
      <li>
        3.1您可通过平台浏览订阅各类视频、图片、信息等内容。您对平台中的内容须自行加以判断，并承担因使用内容而引起的风险。除法律法规明确规定外，您因该内容进行的交易或因前述内容遭受的损害或损失由您自行承担。
      </li>
      <li>
        3.2您可使用平台发表和发布属于您原创或您有权发表的观点看法、文字、信息、图片、音视频等内容，您确认发布的内容不存在侵犯他人知识产权，否则，您须独立承担所发布内容的造成侵权的法律责任。您在平台发布的任何内容、信息均不代表平台的观点、立场或政策，除法律法规明确规定外，您须独立承担所发布内容的责任。
      </li>
      <li>
        3.3您通过平台发布内容时必须遵守有关法律法规，不得利用平台制作、复制、发布、传播、储存任何违反法律法规、危害国家安全、可能对用户财产安全或信息安全造成损失的信息、营销信息、低俗不当信息、不良信息以及不实信息等内容。
      </li>
      <li>
        3.4您可使用平台进行商品与服务的交易，如您是未成年人或限制民事行为能力人，请在监护人同意后进行交易。进行交易时请您务必仔细确认所购商品的内容、限制性要求等重要事项。因此造成的误解或行为和意思表示产生的法律后果均由您承担。如您在平台出售商品，您确认商品质量和品质，不存在侵犯他人合法权益或知识产权。如您发布的视频、图片、文字等内容被其他用户购买使用，平台会根据交易量和市场因素抽取金币作为手续费或佣金。
      </li>
      <li>
        3.5
        您可通过参与平台各类活动或任务获得奖励，或担任兼职客服金币奖励，但您不得采取任何不正当或作弊的违规手段参与活动，否则平台有权限制或取消您参与活动的资格，并有权取消、追讨您已获得、领取的奖励，并追究您的法律责任。
      </li>
      <li>
        3.6
        平台提供OBS参数设置直播场景基础画面的、悬浮框、音效、主播人的音响设计或设置服务。你可以选择默认系统设置，也可以自行保存参数设置。
      </li>
    </ul>
    <h2>第四条 服务费用</h2>
    <ul>
      <li>
        4.1会员费分为月、季度、年度会员。按月/季度/年度计算，不足一月/季度/年度分别按一个月/季度/年度计算。您在消费前请您仔细阅读和识别消费，理性消费，不存在消费的任何误解。
      </li>
      <li>4.2会员费具体以平台的充值页面为准，平台可以调整服务的收费标准。</li>
    </ul>
    <h2>第五条 权利与义务</h2>
    <ul>
      <li>
        5.1
        平台所提供的内容，包括但不限于视频、图片、文字及其组合、界面设计、版面框架、图标、商标等，除涉及第三方授权的软件、内容外，均归属平台所有，受中华人民共和国著作权法、商标法、专利法、反不正当竞争法及国际条约等法律法规的保护。未经书面允许，用户不得为任何目的擅自使用、复制、再造这些内容、或创造与内容有关的派生产品。
      </li>
      <li>
        5.2
        您在平台发布、上传的内容（包括但不限于文字、图片、视频、音频等）应有合法来源，相关内容为您所有或您已获得必要的授权。除非有相反证明，您理解并同意，为使用户内容得到更好的分享及推广，提高其传播价值及影响力，您授予平台免费的、不可撤销的、非排他的、无地域限制的许可使用，包括：存储、下载、使用、传播、复制、修订、改编、汇编、出版、展示、翻译、表演用户内容或制作派生作品，以已知或日后开发的形式、媒体或技术将内容纳入其它作品，再许可第三方按照前述方式使用的权利，以及以自身名义或委托专业第三方对侵犯您上传发布的享有知识产权的内容进行取证、发起投诉或提起诉讼的权利。上述许可包括使用、复制和展示用户内容中受保护的个人形象、肖像、姓名、商标、品牌、标识及其他营销推广素材、物料的权利和许可。
      </li>
      <li>
        5.3平台尊重并保护您及他人的知识产权、名誉权、姓名权、隐私权等合法权益。您保证，在平台上传的文字、图片、视频、音频、链接等不侵犯任何第三方的知识产权、名誉权、姓名权、隐私权等合法权益。否则，平台有权在收到权利方或者相关方通知的情况下移除或删除该涉嫌侵权内容。
      </li>
      <li>
        5.4您认为平台上的内容侵犯您的合法权益，应当向我们客服发送侵权通知。我们将在收到有效通知后进行审核，对可以初步证明构成侵权的内容采取必要措施。对无法认定为侵权的内容，您也将收到相应告知，如果您仍然认为侵权事实存在，新的侵权通知中应当补充证明侵权的证据。
      </li>
    </ul>
    <h2>第六条 违约责任</h2>
    <ul>
      <li>
        6.1如您违反本协议约定，平台有权作出独立判断，立即暂停或终止向您提供部分或全部平台服务，包括禁言、屏蔽信息、删除发布内容、封禁账号、注销账号等措施。您应自行承担自身损失（包括但不限于虚拟财产清空、无法正常使用账号及相关平台服务、无法正常获取您账号内资产或其他权益等），以及相应法律责任。
      </li>
      <li>
        6.2您承担法律责任的形式包括但不限于：对受到侵害者赔偿损失、赔礼道歉、消除影响、返还财产等。如您的行为给平台造成损失的，您应承担全部赔偿责任，包括行政处罚或损害赔偿款等直接损失以及商誉损失、和解款、律师费、诉讼费等间接损失。
      </li>
      <li>
        6.3平台仅按照相关法律法规的规定承担平台责任，我们不对第三方的诽谤、犯罪侵权或其他非法行为承担责任，也不赔偿因此造成的您的损失。
      </li>
    </ul>
    <h2>第七条 协议变更与终止</h2>
    <ul>
      <li>
        7.1 平台有权在必要时修改本协议内容，并将通过合理的方式（包括但不限于平台公告、系统消息、站内信、手机短信、电子邮件等）及合理的期限提前通知您，该等通知于发送之日视为已送达您。您可以通过平台相关页面查阅最新版本的协议内容。本协议变更生效后，如您继续使用平台，即视为您已接受修改后的协议。如您不接受修改后的协议，应当停止使用平台
      </li>
      <li>7.2 协议终止</li>
      <li>
        您注销您的账号或平台通知您终止为您提供平台服务（包括但不限于封禁账号、注销账号等）以及平台软件下架时，本协议将同时终止，将不再另行通知您终止本协议。
      </li>
    </ul>
    <h2>
      第八条
      若您与平台之间发生任何纠纷或争议，由平台公司与您协商解决。协商不成的，您同意将纠纷或争议提交平台公司所在地人民法院管辖。
    </h2>
  </div>
</template>


<script>
export default {
  name: "yhxy", //用户协议
};
</script>



<style lang="scss" scoped>
body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 20px;
  font-size: 16px;
}
h2,
h3,
p {
  color: #333;
  font-size: 16px;
  margin-left: 80px;
}
h1 {
  text-align: center;
  font-size: 32px;
}
ul {
  list-style-type: none;
  padding-left: 20px;
  font-size: 16px;
  margin-left: 80px;
}

ul ul {
  padding-left: 40px;
  font-size: 16px;
}
li,
p {
  margin-bottom: 10px;
  font-size: 16px;
  text-indent: 2ch;
}
.app {
  padding-bottom: 30px;
}
</style>