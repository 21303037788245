<template>
  <div class="app">
    <h1>隐私政策</h1>
    <h2>一、个人信息收集与使用</h2>
    <ul>
      <li>
        平台用户可以通过短视频、图文等形式记录、下载、分享功能可能收集的个人信息范围、收集目的、收集方式，以及拒绝提供个人信息可能导致的结果。请您知悉，您拒绝提供各项功能实现所必要的对应信息时，仍然可以使用其他功能。
      </li>
      <li>
        收集和使用您在使用我们服务过程中主动提供或因使用我们产品和/或服务而产生的个人信息。如果我们要将您的个人信息用于本政策未载明的其它用途，或基于特定目的将已经收集的信息用于其他目的，我们将以合理的方式向您告知，并在使用前再次征得您的同意。您可通过【我-设置-个人信息】查看详情。
      </li>
    </ul>
    <h2>1.账号注册及登录</h2>
    <ul>
      <li>
        1.1当您注册及登录时，如您使用一键登录的功能，基于我们与通信运营商的合作，我们会收集您的【手机号码和网络运营商信息】、微信名信息，以便为您提供快捷的登录服务；同时，为帮助您完成注册过程，我们还将收集您的【地区】，以为您提供初始服务。如不希望提供，您可以在收集页面右上角点击【...】-【跳过此步骤】。
      </li>
      <li>
        1.2如您使用第三方账号进行登录，为了满足网络实名制要求，在您使用发布、评论及其他要求实名认证的功能与服务前，我们将另行收集您的手机号码以完成实名认证。请您谨慎考虑后提供这类信息，若您拒绝提供，您可能无法正常使用相应的功能。
      </li>
      <li>
        1.3
        如果您成功注册账号，我们会在平台应用程序及网站中显示您的名字、头像、地区、简介信息。
      </li>
    </ul>
    <h2>2.信息浏览、发布、收藏、下载、分享功能</h2>
    <ul>
      <li>
        2.1为了向您提供我们最核心的信息展示服务并向您推荐可能感兴趣的笔记及相关信息，我们会收集您填写个人资料、您浏览记录（您的关注、浏览）和点击操作记录（下载、点击、搜索、收藏、下载、分享、评论、发布记录及有关行为）。
      </li>
      <li>
        2.2当您使用发布、编辑内容功能时，我们会请求您授权存储（相册、媒体和其他文件）、相机（摄像头）、麦克风（录音）权限。您如果拒绝授权提供，将无法使用相应功能，但不影响您正常使用平台的其他功能。如通过电脑端可能会访问您的系统存储。
      </li>
      <li>
        2.3当您使用发布、评论、点赞、收藏、分享、下载功能时，您发布的文字、照片、视频、音频、评论、点赞、收藏、分享记录、下载信息会存储在我们的服务器中，因为存储是实现这一功能所必需的。我们会以加密的方式存储，您也可以随时删除这些信息。
      </li>
      <li>
        2.4搜索功能。当您使用平台搜索功能时，我们会收集分析您的搜索指令（可能是文本或者图像形式）、阅读记录和访问时间、评论和互动记录。收集此信息可以向您提供您所需要的内容和可能更感兴趣的服务，同时亦可以改进我们的产品和服务。
      </li>
      <li>
        2.5当您购买商品或服务时，我们将访问您的金币钱包功能。购买、查询、使用虚拟财产。为了方便您查询虚拟财产，并尽量降低可能存在的风险，【钱包】会记录您的充值、余额与使用情况。并收集您的交易商品/服务信息、推荐收益、任务奖励、订单号、下单时间、交易金额、支付方式等。
      </li>
      <li>
        2.6当您申请开票时，根据法律法规的要求，我们可能会收集您的身份信息，包括您的真实姓名、身份证件号码、邮箱、手机号。
      </li>
      <li>
        2.7客服和售后服务功能。当您联系我们的客服或提出我们的产品与/或服务的售后申请时，我们系统可能会记录您与客服之间的通讯记录。为了您的账号与系统安全，我们可能需要您提供相关个人信息与您之前提供的个人信息相匹配以验证您的用户身份。验证成功后，我们可能会收集您与我们的沟通信息（账号信息、订单信息、您为了证明相关事实而提供的图片/视频/文字信息及有关信息）、您的联系方式以及您与我们的沟通记录，包括线上沟通记录、电话录音，以便尽快为您解决问题和改进我们的产品与/或服务。同时，我们可能会以电话、短信、站内信及相关方式主动联系您，沟通您在使用我们产品及服务过程中产生的问题。
      </li>
      <li>
        为了保证服务的体验、处理服务纠纷，您与客服间的通话信息可能会被录音。有关上述信息的收集和使用规则如下：
      </li>
      <li>
        2.8
        当您使用平台或者商家发生纠纷时，我们可调取并使用录音信息、聊天记录、图片作为解决纠纷的参考依据。为了向您提供客服和售后服务，我们可能将您的通话录音、聊天记录存储在第三方客服系统中，我们会通过协议约束等方式，确保第三方无法访问您的通话录音；除以下情形外，平台不会将录音提供给任何人（包括通话主、被叫方）：a.有权机关依法定程序调取；b.被录音的一方持司法机关出具的法律文件依法调取。
      </li>
    </ul>
    <h2>二、信息共享、转让、公开披露</h2>
    <h2>1.共享：</h2>
    <ul>
      <li>
        对于您的个人信息，我们不会与任何公司、组织和个人进行共享，除非存在以下一种或多种情形：
      </li>
      <li>（1）事先已得到您的授权；</li>
      <li>（2）您自行提出的；</li>
      <li>（3）与商业合作伙伴的必要共享：</li>
      <li>
        您理解并知悉，为了向您提供更完善、优质的产品和服务，我们将授权商业合作伙伴为您提供部分服务。
      </li>
      <li>具体共享以修改和公布的的合作伙伴合作信息清单为准</li>
    </ul>
    <h2>2.公开披露：</h2>
    <ul>
      <li>
        公开披露是指向社会或不特定人群发布信息的行为。原则上，我们不会对您的个人信息进行公开披露。但下述情况除外：
      </li>
      <li>（1）因公布账号中奖、处罚通知时展示必要的相关脱敏信息；</li>
      <li>（2）取得您的明示同意后。</li>
    </ul>
    <h2>3.共享、转让、公开披露个人信息授权同意的例外情形</h2>
    <ul>
      <li>
        根据相关法律法规的规定，在以下情形中，我们可能在未事先征得您的授权同意的情况下共享、转让、公开披露您的个人信息：
      </li>
      <li>
        （1）为订立、履行个人作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；
      </li>
      <li>（2）为履行法定职责或者法定义务所必需；</li>
      <li>
        （3）为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；
      </li>
      <li>
        （4）为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；
      </li>
      <li>
        （5）依照《个人信息保护法》规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；
      </li>
      <li>（6）法律法规规定的其他情形。</li>
      <li>
        请您了解，根据现行法律规定及监管要求，共享、转让经匿名化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，无需另行向您通知并征得您的同意。
      </li>
    </ul>
    <h2>三、我们如何存储和保护您的个人信息</h2>
    <h2>1.存储：</h2>
    <ul>
      <li>
        存储地点：我们将委托境内第三方存储。以下情形下，我们会确保在履行了法律规定的义务后，向境外实体提供您的个人信息：
      </li>
      <li>（1）适用的法律有明确规定;</li>
      <li>（2）获得您的明确授权;</li>
      <li>（3）您通过互联网进行跨境交易等个人主动行为。</li>
      <li>
        针对以上情形，我们会通过合同等形式确保以不低于本政策规定的程度保护您的个人信息。
      </li>
      <li>
        存储时间：我们承诺始终按照法律的规定在合理必要期限内在存储您个人信息。存储期限不超过6个月，超出上述期限后，我们将删除您的个人信息或对您的个人信息进行匿名化处理。
      </li>
      <li>
        如我们停止运营，我们将及时停止收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对所持有的您的个人信息进行删除或匿名化处理。
      </li>
    </ul>
    <h2>2.保护：</h2>
    <ul>
      <li>
        为了保护您的个人信息安全，我们将努力采取各种符合行业标准的安全措施来保护您的个人信息以最大程度降低您的个人信息被毁损、盗用、泄露、非授权访问、使用、披露和更改的风险。我们将积极建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用，确保未收集与我们提供的服务无关的个人信息。
      </li>
      <li>
        当我们的产品或服务发生停止运营的情形时，我们会及时停止继续收集个人信息的活动。上述变更，我们将以推送通知、公告及相关形式通知你，并在合理的期限内删除你的个人信息或进行匿名化处理（所谓“匿名化处理”，是指通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。个人信息经匿名化处理后所得的信息不属于个人信息。）
      </li>
    </ul>
    <h2>四、您如何管理您的个人信息</h2>
    <h2>我们非常重视并尽全力保障您对自己个人信息的相关权利。</h2>
    <ul>
      <li>1.自主决定接收信息</li>
      <li>
        在关注中会根据您主动选择关注的账号信息进行展示，您可以根据需要自主选择关注或取消关注。
      </li>
      <li>2.个人信息副本的访问、获取、更正和删除相关信息</li>
      <li>
        我们通过交互界面的设计，为您提供了可自行访问、获取、更正和删除相关个人信息的渠道，您可通过以下方式进行设置：
      </li>
      <li>（1）通过【我-编辑资料】访问、获取、更正自己的个人资料；</li>
      <li>（2）通过【我-设置-账号与安全】设置、访问、获取、更正账号信息；</li>
      <li>
        （3）通过【我-设置-隐私】设置、访问、获取、更正隐私信息，含评论设置、@设置、通讯录推荐设置、微博推荐设置、位置信息设置和黑名单设置；
      </li>
      <li>
        除上述外，有下列情形之一的，我们将应当主动删除您的个人信息，您也有权请求我们删除：
      </li>
      <li>（1）处理目的已实现、无法实现或者为实现处理目的不再必要；</li>
      <li>（2）我们已停止提供产品或者服务，或者保存期限已届满；</li>
      <li>
        （4）如您有足够的理由认为我们违反法律、行政法规或者违反约定处理您的个人信息；
      </li>
      <li>（5）法律、行政法规规定的其他情形。</li>
    </ul>
    <h2>五、我们如何更新隐私政策</h2>
    <ul>
      <li>
        1.为了给您提供更好的服务，我们会根据产品的更新情况及法律法规的相关要求更新本政策的条款，这些更新将构成本政策的一部分。未经您明确同意，我们不会削减您依据当前生效的本政策所应享受的权利。
      </li>
      <li>
        2.如遇本政策更新，我们会通过APP客户端推送通知或其他合理方式通知您，以便您能及时了解本政策的最新版本。
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "yszc", //隐私政策
};
</script>
<style lang="scss" scoped>
body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 20px;
  font-size: 16px;
}
h2,
h3,
p {
  color: #333;
  font-size: 16px;
  margin-left: 80px;
}
h1 {
  text-align: center;
  font-size: 32px;
}
ul {
  list-style-type: none;
  padding-left: 20px;
  font-size: 16px;
  margin-left: 80px;
}

ul ul {
  padding-left: 40px;
  font-size: 16px;
}
li,
p {
  margin-bottom: 10px;
  font-size: 16px;
  text-indent: 2ch;
}
.app {
  padding-bottom: 30px;
}
</style>