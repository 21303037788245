import { render, staticRenderFns } from "./zxxy.vue?vue&type=template&id=c2a9d1e8&scoped=true&"
import script from "./zxxy.vue?vue&type=script&lang=js&"
export * from "./zxxy.vue?vue&type=script&lang=js&"
import style0 from "./zxxy.vue?vue&type=style&index=0&id=c2a9d1e8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2a9d1e8",
  null
  
)

export default component.exports